/*             Navigation menu                */

/*             Navigation Tabs                 */
.nav-tabs-navigation {
	text-align: center;
	border-bottom: 1px solid $medium-pale-bg;
	margin-bottom: 30px;

	.nav > .nav-item > .nav-link {
		padding-bottom: 20px;
	}
}
.nav-tabs-wrapper {
	display: inline-block;
	margin-bottom: -6px;
	margin-left: 1.25%;
	margin-right: 1.25%;
	position: relative;
	width: auto;
}
.nav-tabs {
	border-bottom: 0 none;
	font-size: $font-size-medium;
	font-weight: $font-weight-bold;
	.nav-item {
		.nav-link {
			border: 0 none;
			color: $other-medium-gray;
			background-color: transparent;
		}
		.nav-link:hover {
			color: $font-color;
		}
		.nav-link.active {
			color: $font-color;
		}
	}

	.nav-item {
		color: $font-color;
		position: relative;

		.nav-link.active,
		.nav-link.active:hover,
		.nav-link.active:focus {
			background-color: transparent;
			border: 0 none;
			//height: 50px;
			&:after {
				border-bottom: 11px solid $white-color;
				border-left: 11px solid rgba(0, 0, 0, 0);
				border-right: 11px solid rgba(0, 0, 0, 0);
				content: "";
				display: inline-block;
				position: absolute;
				right: 40%;
				bottom: 5px;
			}

			&:before {
				border-bottom: 11px solid $medium-pale-bg;
				border-left: 11px solid rgba(0, 0, 0, 0);
				border-right: 11px solid rgba(0, 0, 0, 0);
				content: "";
				display: inline-block;
				position: absolute;
				right: 40%;
				bottom: 6px;
			}
		}
	}
	.nav-item.show .nav-link {
		background-color: transparent;
	}
	.dropdown-menu {
		margin-top: -6px;
		margin-left: -46px;
		border-radius: 8px;

		.dropdown-item:hover,
		.dropdown-item.active {
			color: #ffffff;
			background-color: #68b3c8;
		}
		:before {
			border-bottom: 11px solid $medium-pale-bg;
			border-left: 11px solid rgba(0, 0, 0, 0);
			border-right: 11px solid rgba(0, 0, 0, 0);
			content: "";
			display: inline-block;
			position: absolute;
			right: 12px;
			top: -11px;
		}
		:after {
			border-bottom: 11px solid $pale-bg;
			border-left: 11px solid rgba(0, 0, 0, 0);
			border-right: 11px solid rgba(0, 0, 0, 0);
			content: "";
			display: inline-block;
			position: absolute;
			right: 12px;
			top: -10px;
		}
	}
}
.profile-content {
	& .tab-content {
		& .tab-pane {
			min-height: 200px;
		}
		& #tweets,
		#connections,
		#media {
			height: 100%;
		}
	}
}
/*             Navigation Pills               */

.nav-pills {
	.nav-item + .nav-link {
		margin-left: 0;
	}
	.nav-item .nav-link {
		border: 1px solid $default-color;
		border-radius: 0;
		color: $default-color;
		font-weight: $font-weight-bold;
		margin-left: -1px;
		padding: 10px 25px;
	}
	.nav-item.active .nav-link,
	.nav-item.active .nav-link:hover,
	.nav-item.active .nav-link:focus {
		background-color: $default-color;
		color: #ffffff;
	}
	.nav-item:first-child .nav-link {
		border-radius: 30px 0 0 30px !important;
		margin: 0;
	}
	.nav-item:last-child .nav-link {
		border-radius: 0 30px 30px 0 !important;
	}
	.nav-item .nav-link.active {
		background-color: $default-color;
		color: $white-color;
	}
}
.nav-pills-primary {
	.nav-item {
		.nav-link {
			border: 1px solid $primary-color !important;
			color: $primary-color !important;
			&.active {
				border: 1px solid $primary-color !important;
				color: $white-color !important;
			}
		}
	}
}
.nav-pills-danger {
	.nav-item {
		.nav-link {
			border: 1px solid $danger-color !important;
			color: $danger-color !important;
			&.active {
				border: 1px solid $danger-color !important;
				color: $white-color !important;
			}
		}
	}
}
.nav-pills-info {
	.nav-item {
		.nav-link {
			border: 1px solid $info-color !important;
			color: $info-color !important;
			&.active {
				border: 1px solid $info-color !important;
				color: $white-color !important;
			}
		}
	}
}
.nav-pills-success {
	.nav-item {
		.nav-link {
			border: 1px solid $success-color !important;
			color: $success-color !important;
			&.active {
				border: 1px solid $success-color !important;
				color: $white-color !important;
			}
		}
	}
}
.nav-pills-warning {
	.nav-item {
		.nav-link {
			border: 1px solid $warning-color !important;
			color: $warning-color !important;
			&.active {
				border: 1px solid $warning-color !important;
				color: $white-color !important;
			}
		}
	}
}
