@mixin navbar-color($color) {
	background-color: $color;
}

@mixin center-item() {
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
	position: absolute;
}
