.card-register {
	background-color: #ff8f5e;
	border-radius: 8px;
	color: #fff;
	max-width: 350px;
	margin: 20px 0 70px;
	min-height: 400px;
	padding: 30px;

	& label {
		margin-top: 15px;
	}
	& .card-title {
		color: #b33c12;
		text-align: center;
	}
	& .btn {
		margin-top: 30px;
	}
	& .forgot {
		text-align: center;
	}
}
