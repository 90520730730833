.nav {
	.nav-item {
		.nav-link:hover,
		.nav-link:focus {
			background-color: transparent;
		}
	}
}
.navbar {
	border: $none;
	font-size: $font-size-base;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
	padding: 0;
	background: $white-color;
	box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

	.navbar-brand {
		font-weight: $font-weight-bold;
		margin: $navbar-margin-brand;
		padding: $navbar-padding-brand;
		font-size: $font-size-base;
		color: $default-color;
		text-transform: uppercase;
	}
	.navbar-nav {
		.nav-item .nav-link {
			line-height: 1.6;
			margin: $navbar-margin-a;
			padding: $navbar-padding-a;
			opacity: 0.8;
			font-size: $font-size-small;
			text-transform: uppercase;
			font-weight: 600;
			color: $default-color;
		}
		.nav-item .nav-link.btn {
			margin: $navbar-margin-a-btn;
			padding: 9px;
		}
		.nav-item .nav-link [class^="fa"] {
			font-size: 17px;
			position: relative;
			margin: 0px -5px;
			right: 5px;
		}
		.dropdown-menu {
			border-radius: $border-radius-extreme;
			margin-top: 1px;
		}
		.nav-item {
			.btn {
				i {
					color: $white-color;
				}
			}
		}
	}
	.navbar-collapse {
		& .nav-item {
			& .nav-link {
				p {
					display: inline;
				}
			}
			& .dropdown-item {
				i {
					margin: 0 10px;
					margin: 0 10px 0px 5px;
					font-size: 18px;
					position: relative;
					top: 3px;
				}
			}
		}
		&.show {
			& .navbar-nav {
				& .nav-item {
					padding-right: 10px;
				}
			}
		}
	}
	#navbarSupportedContent {
		.nav-item {
			position: relative;
		}
	}
	.notification-bubble {
		padding: 0.4em 0.6em;
		position: absolute;
		top: 10px;
		right: -2px;
	}
	.btn {
		margin: 14px 3px;
		font-size: $font-size-small;
		i {
			font-size: 14px;
			position: relative;
			top: 2px;
		}
	}
	.btn-simple {
		font-size: $font-size-medium;
	}
	.caret {
		@include center-item();
	}
	&.navbar-transparent {
		padding-top: 25px;
	}
	.logo-container {
		margin-top: 5px;
		.logo {
			overflow: hidden;
			border-radius: 50%;
			border: 1px solid #333333;
			width: 50px;
			float: left;

			img {
				width: 100%;
			}
		}
		.brand {
			font-size: 18px;
			color: #ffffff;
			line-height: 20px;
			float: left;
			margin-left: 10px;
			margin-top: 5px;
			width: 75px;
			height: 50px;
		}
	}
}

.navbar-absolute {
	position: absolute;
	width: 100%;
	padding-top: 10px;
	z-index: 1029;
}

.bd-docs {
	.navigation-example {
		.navbar.navbar-transparent {
			padding-top: 0;
			padding: 20px 0;
			margin-top: 20px;
		}
	}
}
