// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
	background-color: $btn-color;
	border-color: $btn-color;
	color: $white-color;
	@include opacity(1);

	&:hover,
	&:focus,
	&:active,
	&.active,
	&:active:focus,
	&:active:hover,
	&.active:focus,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled).active:focus,
	.show > &.dropdown-toggle {
		background-color: $btn-states-color;
		color: $white-color;
		border-color: $btn-states-color;
		box-shadow: none;
	}

	.caret {
		border-top-color: $white-color;
	}

	&.disabled,
	&:disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: $btn-states-color;
			border-color: $btn-states-color;
		}
	}

	&.btn-link {
		color: $btn-color;

		&:hover,
		&:focus,
		&:active,
		&.active,
		&:focus:active,
		.open > &.dropdown-toggle {
			background-color: $transparent-bg !important;
			color: $btn-states-color !important;
		}

		.caret {
			border-top-color: $btn-color;
		}
	}

	.caret {
		border-top-color: $white-color;
	}
}

@mixin btn-outline-styles($btn-color, $btn-states-color) {
	border-color: $btn-color;
	color: $btn-color;
	@include opacity(1);

	&:hover,
	&:focus,
	&:active,
	&.active,
	&:active:focus,
	&:active:hover,
	&.active:focus,
	.show > &.dropdown-toggle {
		background-color: $btn-color !important;
		color: $fill-font-color;
		border-color: $btn-color !important;
		.caret {
			border-top-color: $fill-font-color;
		}
	}

	.caret {
		border-top-color: $white-color;
	}

	&.disabled,
	&:disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: $transparent-bg;
			border-color: $btn-color;
		}
	}
}

@mixin btn-size(
	$padding-vertical,
	$padding-horizontal,
	$font-size,
	$line-height
) {
	font-size: $font-size;
	padding: $padding-vertical $padding-horizontal;

	&.btn-simple {
		padding: $padding-vertical + 2 $padding-horizontal;
	}
}
