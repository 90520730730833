.alert {
	border: 0;
	border-radius: 0;
	color: $white-color;
	padding: 10px 15px;
	font-size: 14px;

	& .close {
		font-size: $font-size-large;
		color: $white-color;
	}

	.container & {
		border-radius: 4px;
	}
	.navbar & {
		border-radius: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 85px;
		width: 100%;
		z-index: 3;
	}
	.navbar:not(.navbar-transparent) & {
		top: 70px;
	}
	.alert-icon {
		display: block;
		font-size: 30px;
		left: 15px;
		position: absolute;
		top: 50%;
		margin-top: -22px;
	}
	.alert-wrapper {
		&.message {
			padding-right: 60px;
		}
		i {
			position: relative;
			font-size: 20px;
			top: 5px;
			margin-top: -5px;
			font-weight: $font-weight-bold;
		}
	}
}
.alert-info {
	background-color: $bg-info;
}
.alert-success {
	background-color: $bg-success;
}
.alert-warning {
	background-color: $bg-warning;
}
.alert-danger {
	background-color: $bg-danger;
}
