.pswp-thumbnails {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.pswp-thumbnail {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
@media (min-width: 576px) {
	.pswp-thumbnail {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media (min-width: 768px) {
	.pswp-thumbnail {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
}
