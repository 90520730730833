.panel {
	border: 0;
	border-bottom: 1px solid $medium-gray;
	box-shadow: none;
}
.panel-default > .panel-heading {
	background-color: $white-color;
	border-color: $white-color;
}
.panel-group .panel {
	border-radius: 0;
}
.panel-title {
	font-size: $font-size-h5;
	a {
		display: block;
		padding: 0.75rem;
	}
	i {
		float: right;
		padding-top: 5px;
	}
}
.panel-title a:hover,
.panel-title a:focus {
	text-decoration: none;
}
.gsdk-collapse {
	display: block;
	height: 0px;
	visibility: visible;
	overflow: hidden;
}
#accordion {
	.panel-title a:hover,
	.panel-title a:focus {
		color: $danger-color;
	}
	.card-header {
		background-color: $white-color;
	}
}
// .panel-default > .panel-heading + .panel-collapse.gsdk-collapse > .panel-body {
//     box-shadow: inset 0 7px 10px -7px rgba(0,0,0,0.14);
// }
.card-collapse {
	padding: 0 !important;
}
.panel-title a[aria-expanded="true"] {
	i {
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
		@include transition($slow-transition-time, $transition-linear);
	}
}
